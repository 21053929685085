import React, { useState } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import Meta from '../components/Meta';

const SemesterExamRoutineScreen = () => {
  const [openPDF, setOpenPDF] = useState(null); // Tracks the currently opened PDF

  // Define the file paths for the 5 PDFs
  const pdfLinks = [
    `${process.env.PUBLIC_URL}/Spring-2025-Mid-Term-Exam-Routine/BBA, Midterm Routine, Spring 2025.pdf`,
    `${process.env.PUBLIC_URL}/Spring-2025-Mid-Term-Exam-Routine/CSE, Midterm Routine, Spring 2025.pdf`,
    `${process.env.PUBLIC_URL}/Spring-2025-Mid-Term-Exam-Routine/ELL, Midterm Routine, Spring 2025.pdf`,
    `${process.env.PUBLIC_URL}/Spring-2025-Mid-Term-Exam-Routine/LLB, Midterm Routine, Spring 2025.pdf`,
    `${process.env.PUBLIC_URL}/Spring-2025-Mid-Term-Exam-Routine/MICR, Midterm Routine, Spring 2025.pdf`,
  ];

  const togglePDF = (pdfNum) => {
    setOpenPDF(openPDF === pdfNum ? null : pdfNum);
  };

  return (
    <Container>
      <Meta
        title="Midterm Exam Routine"
        description="Midterm Exam Routine available at Notre Dame University Bangladesh"
        keywords="ndub semester midterm exam routine"
      />

      {/* Page Heading */}
      <Typography
        variant="h4"
        align="center"
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          fontWeight: 'bold',
          color: '#001f4d', // Dark navy blue
        }}
      >
        Spring 2025 Semester - Midterm Exam Routine
      </Typography>
      <Typography
        variant="body1"
        align="center"
        style={{
          marginBottom: '30px',
          fontWeight: 'bold',
          color: '#33475b', // Subtle navy shade for the description
        }}
      >
        Select a program below to view the midterm exam routine for the semester.
      </Typography>

      {/* Buttons and PDF Viewer */}

      <Grid container spacing={2}>
        {['BBA', 'CSE', 'English', 'LLB', 'Microbiology'].map((buttonText, index) => (
          <Grid item xs={12} key={index}>
            <Button
              onClick={() => togglePDF(index)}
              variant="contained"
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              {openPDF === index ? `Close ${buttonText}` : `${buttonText}`}
            </Button>
            {openPDF === index && (
              <div style={{ marginTop: '10px', position: 'relative' }}>
                <object
                  data={pdfLinks[index]}
                  type="application/pdf"
                  style={{
                    width: '100%',
                    height: '80vh', // Adjust height for viewport
                    border: 'none',
                  }}
                  aria-label={`${buttonText} PDF`}
                >
                  <p>
                    Your browser does not support PDFs. You can{' '}
                    <a
                      href={pdfLinks[index]}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: '#1E90FF', // Blue color
                        fontWeight: 'bold', // Bold text
                        textDecoration: 'underline', // Underlined link
                      }}
                    >
                      download the PDF
                    </a>{' '}
                    instead.
                  </p>
                </object>
              </div>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SemesterExamRoutineScreen;
